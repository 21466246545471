.rootSpin>.ant-spin-container {
  width: 100vw;
  height: 100vh;
  // min-width: 1440px;

}

@media (max-width: 828px) {
  .rootSpin {
    width: 100%;
    height: 100%;
  }

  .rootSpin>.ant-spin-container {
    width: 100%;
    height: 100%;
    // min-width: 100%;

  }
}